import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import './EventCard.css';
import clock from './eventslideimg/clock@3x.png'
import calendar from './eventslideimg/calendar-date@3x.png'
import pin from './eventslideimg/marker-pin-01@3x.png'
import paymenticon from './eventslideimg/paymentIcon.png'
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { async } from '@firebase/util';

const auth = getAuth();

function Events(props) {
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(null);
    const [isSignedup, setIsSignedup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [member, setMember] = useState(props.value)
    const [user, setUser] = useState(props.value)

    useEffect(() => {
        // Set up the authentication listener
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {

                console.log("user is signed in profile", user)
                // User is signed in
                setUser(user);
                fetchEvents(user.uid)
                // fetchMember(user)

                // Perform any necessary actions for a logged-in user
            } else {
                // User is not signed in
                console.log("user is not signed in")
                setUser(null);
                window.location.href = 'https://curve.club';
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, []);


    const fetchEvents = async (userid) => {
        try {

            // Get the list of events
            const eventsRef = db.collection('events');
            const snapshot = await eventsRef.get();
            const currentDate = new Date();

            const fetchedEvents = await Promise.all(
                snapshot.docs.map(async doc => {
                    const event = { id: doc.id, ...doc.data() };

                    const eventDate = event.startDatetime && event.startDatetime.seconds
                        ? new Date(event.startDatetime.seconds * 1000)
                        : null;

                    if (eventDate && eventDate >= currentDate && event.isShowing === true && event.hasMembers) {
                        // Check if the user is signed up for this event


                        if (userid) {
                            const memberRef = db.collection('members').where('uid', '==', userid);


                            try {
                                const querySnapshot = await memberRef.get();

                                if (!querySnapshot.empty) {
                                    const document = querySnapshot.docs[0];
                                    const events = document.data().events;
                                    setMember(document.data())

                                    if (events.includes(event.id)) {
                                        return { ...event, isSignedUp: true };
                                    }
                                }
                            } catch (error) {
                                console.error('Error getting member document:', error);
                            }
                        }

                        return { ...event, isSignedUp: false };
                    }

                    return null;
                })
            );

            // Filter out null values (events that don't match criteria)
            const filteredEvents = fetchedEvents.filter(event => event !== null);

            // Sort events by closest date first
            const sortedEvents = filteredEvents.sort((a, b) => {
                const dateA = a.startDatetime && a.startDatetime.seconds
                    ? new Date(a.startDatetime.seconds * 1000)
                    : null;
                const dateB = b.startDatetime && b.startDatetime.seconds
                    ? new Date(b.startDatetime.seconds * 1000)
                    : null;

                return dateA - dateB;
            });

            // console.log('Fetched and sorted events:', sortedEvents);
            setEvents(sortedEvents);
        } catch (error) {
            console.error('Error retrieving events:', error);
        }
    };



    const signUserUp = async (event) => {
        const uid = user.uid;

        if (!uid) {
            return;
        }

        const eventRef = db.collection("events").doc(event.id);
        const memberAttendingRef = eventRef.collection("membersAttending").doc(uid);

        memberAttendingRef.set({
            timestamp: new Date()
        })
            .then(() => {
                console.log("Member successfully added to membersAttending collection.");

                db.collection("members").where("uid", "==", uid).get()
                    .then((querySnapshot) => {
                        if (querySnapshot.empty) {
                            console.log("Member document not found");
                            setIsLoading(false)
                            return;
                        }

                        const document = querySnapshot.docs[0];
                        console.log("member document", document.id);
                        const memberRef = db.collection("members").doc(document.id);

                        memberRef.get()
                            .then((doc) => {
                                const events = doc.data().events || [];
                                events.push(event.id);

                                memberRef.update({ events })
                                    .then(() => {
                                        console.log("Member document successfully updated with event id.");
                                        setEvents(prevEvents => prevEvents.map(prevEvent => {
                                            if (prevEvent.id === event.id) {
                                                return { ...prevEvent, isSignedUp: true };
                                            }
                                            return prevEvent;
                                        }));
                                        setIsSignedup(true);
                                        setIsLoading(false)
                                    })
                                    .catch((error) => {
                                        console.log("Error updating member document:", error);
                                        setIsSignedup(false);
                                        setIsLoading(false)
                                    });
                            })
                            .catch((error) => {
                                console.log("Error retrieving member document:", error);
                            });
                    })
                    .catch((error) => {
                        console.log("Error getting member document:", error);
                        setIsLoading(false)
                    });
            })
            .catch((error) => {
                console.log("Error updating membersAttending collection:", error);
                setIsSignedup(false);
                setIsLoading(false)
            });

    }

    const handleSignUp = async (event) => {
        if (event.externalURL) {
            window.open(event.externalURL, '_blank');
        }

        //PREPAYMENTS ON WEB
        if (event.prepayment) {
            // const stripe_price = event.price * 100
            const confirmed = window.confirm(`Are you sure you want to sign up? Your card will be charged ${event.price}`);

            console.log("member", member)

            if (confirmed) {

                const details = {
                    "stripe_price": event.stripe_price,
                    "customerid": member.customer,
                    "paymentid": member.payment_method,
                    "product_id": event.product_id,
                    "page_url": `event/${event.id}`,
                    "eventid": event.id,
                    "user": user.uid
                }
                triggerHTTP(details)
            }
        }

        else {
            const confirmed = window.confirm("Are you sure you want to sign up?");

            checkIfSignedin(event)

            if (confirmed) {
                signUserUp(event)
            }
        }

    };


    const handleCancelSignup = async (event) => {

        console.log("event", event)

        if (event.prepayment) {

            const confirmed = window.confirm("Are you sure you want to cancel? Please email memberships@curve.club for your refund");

            if (confirmed) {
                console.log('Cancel sign up button clicked!');
                cancelSignup(event)
            }

        }
        else {

            const confirmed = window.confirm("Are you sure you want to cancel?");

            if (confirmed) {
                console.log('Cancel sign up button clicked!');
                cancelSignup(event)
            }

        }


    };

    const cancelSignup = async (event) => {
        const uid = user.uid

        if (!uid) {
            return;
        }

        const eventRef = db.collection("events").doc(event.id);
        const memberAttendingRef = eventRef.collection("membersAttending").doc(uid);

        memberAttendingRef.delete()
            .then(() => {
                console.log("Member successfully removed from membersAttending collection.");

                db.collection("members").where("uid", "==", uid).get()
                    .then((querySnapshot) => {
                        if (querySnapshot.empty) {
                            console.log("Member document not found");
                            setIsLoading(false)
                            return;
                        }

                        const document = querySnapshot.docs[0];
                        console.log("member document", document.id);
                        const memberRef = db.collection("members").doc(document.id);

                        memberRef.get()
                            .then((doc) => {
                                const events = doc.data().events || [];
                                const updatedEvents = events.filter((eventId) => eventId !== event.id);

                                memberRef.update({ events: updatedEvents })
                                    .then(() => {
                                        console.log("Member document successfully updated. Event removed from events array.");
                                        setIsSignedup(false);
                                        setIsLoading(false)
                                    })
                                    .catch((error) => {
                                        console.log("Error updating member document:", error);
                                        setIsSignedup(true);
                                        setIsLoading(false)
                                    });
                            })
                            .catch((error) => {
                                console.log("Error retrieving member document:", error);
                                setIsLoading(false)
                            });
                    })
                    .catch((error) => {
                        console.log("Error getting member document:", error);
                        setIsLoading(false)
                    });
            })
            .catch((error) => {
                console.log("Error removing member from membersAttending collection:", error);
                setIsLoading(false)
            });
    }

    const triggerHTTP = async (detailsObject) => {

        console.log("triggered")
        const superagent = require('superagent');


        try {

            console.log("triggered 2", detailsObject)

            const response = await superagent
                .post('https://stripe.ionaryder1.repl.co/member_portal_checkout')
                // .post('https://stripe-curve.herokuapp.com/event_payment')
                .send(detailsObject)
                .set('Content-Type', 'application/json')

            console.log(response.status)

            if (response.status >= 200 && response.status < 400) {
                console.log("success calling trigger!");
                const body = await response.body
                console.log("response", body.url)
                window.location.href = body.url
                // signUserUp(event)
                // alert("You're signed up!")
                return ({ status: 200 });
            }
            else {
                console.log("error here")
                throw new Error("other response status returned: " + response.status);
            }

        }
        catch (err) {
            console.log("error here", err)
            // alert("Error:", err)
            alert("Unable to sign up, there was an issue charging your card please get in touch with memberships@curve.club")
            // throw new Error("error calling triggerHTTP: " + err);

        }
    }

    const checkIfSignedin = async (event) => {
        console.log(event.title, "the event")
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in
                console.log('User is logged in');
                // Perform any necessary actions for a logged-in user
            } else {
                console.log("user is not logged in")
                alert("Please login before you sign up to an event")
            }
        });
    }

    return (
        <div>
            <div className="event-slide">
                {events.map((event) => (
                    <div key={event.id} className="event-card">
                        <img src={event.url} alt="Event" className="event-image" />
                        <div className="format-details">
                            <div className='event-title-container-123'>
                                <h3 className='event-title'>{event.title}</h3>
                            </div>
                            <div className='vertical-container'>

                                <div className="details-container">
                                    <img src={calendar} alt="clock" className='event-icons' />
                                    {event.date && event.startDatetime.seconds && (
                                        <p>{new Date(event.startDatetime.seconds * 1000).toLocaleString("en-US", { dateStyle: "long" })}</p>
                                    )}
                                </div>
                                <div className="details-container">
                                    <img src={clock} alt="clock" className='event-icons' />
                                    <p>
                                        {new Date(event.startDatetime.seconds * 1000).toLocaleString("en-US", { timeStyle: "short" })}
                                        -
                                        {new Date(event.endDatetime.seconds * 1000).toLocaleString("en-US", { timeStyle: "short" })}
                                    </p>
                                </div>
                                <div className="details-container">
                                    <img src={pin} alt="pin" className='event-icons' />
                                    <p>{event.location}</p>
                                </div>
                                <div className="details-container">
                                    <img src={paymenticon} alt="paymenticon" className='event-icons' />
                                    <p>{event.price}</p>
                                </div>
                            </div>

                            <Link to={`/event/${event.id}`} className="view-button">
                                VIEW
                            </Link>
                            {!event.isSignedUp && (
                                <div onClick={() => handleSignUp(event)} className="register-button">
                                    SIGN UP
                                </div>
                            )}
                            {event.isSignedUp && (
                                <div onClick={() => handleCancelSignup(event)} className="cancel-button">
                                    CANCEL
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Events;